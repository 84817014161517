import React from 'react'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import Container from '../components/container'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain } from '../containers'
import Hero from '../components/hero'
import { Button } from '@material-ui/core';
import { MdChevronRight, MdLabel, MdArrowForward, MdStar, MdBrightness1, MdBookmark, MdLocalOffer } from "react-icons/md";
import { FcCalculator, FcBullish, FcMoneyTransfer, FcComboChart, FcPositiveDynamic,
  FcBearish, FcHighPriority, FcCalendar, FcHome, FcCurrencyExchange, FcFlashOn, FcAutomotive, FcIcons8Cup, 
  FcPieChart, FcMindMap, FcList, FcClock, FcSportsMode, FcOnlineSupport } from "react-icons/fc";
import EmailOptin from '../components/EmailOptin'
import FeaturedCalcs from '../components/FeaturedCalcs'
import Eth from "../assets/svgs/eth.svg";
import Ada from "../assets/svgs/ada.svg";
import Algo from "../assets/svgs/algo.svg";
import Atom from "../assets/svgs/atom.svg";
import Dot from "../assets/svgs/dot.svg";
import Ksm from "../assets/svgs/ksm.svg";
import Sol from "../assets/svgs/sol.svg";
import Usdc from "../assets/svgs/usdc.svg";
import Xtz from "../assets/svgs/xtz.svg";
import Flow from "../assets/svgs/flow.svg";
import Kava from "../assets/svgs/kava.svg";
import Fire from "../assets/svgs/fire.svg";
import FatFire from "../assets/svgs/fat-fire-FF.svg";
import CoastFire from "../assets/svgs/coast-fire-C.svg";
import BaristaFire from "../assets/svgs/barista-fire.svg";
import Avax from "../assets/svgs/avax.svg";

import GoogleAdWrapper from '../components/GoogleAd'
import { MintMobileAd } from '../components/MintMobileAd'
import { GumroadAd } from '../components/GumroadAd'
import FeaturedInSection from '../components/featured-in'

const PostImage = styled(Img)`
  height: 220px;
`

const PostPreviewContainer = styled.div`
  /* padding: 24px; */
  box-sizing: border-box;
  display: grid;
  align-content: flex-start;
  grid-template-columns: 300px 1fr;
  background-color: ${props => props.theme.theme.bg.primary};
  color: ${props => props.theme.theme.text.primary};
  /* border: 1px solid ${ props => props.theme.theme.border.secondary}; */
  /* border: 3px solid ${props => props.theme.theme.bg.primary}; */
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  height: 100%;
  grid-gap: 24px;
  border-radius: 8px;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in-out;

  h2{
    font-size: 1.4rem;
    margin: 12px 0 0px 0;
  }

  &:hover {
      /* border: 1px solid ${ props => props.theme.theme.border.tertiary}; */
    /* background-color: ${props => props.theme.theme.bg.tertiary}; */
    box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1);
    transition: box-shadow 0.2s ease-in-out;

      h2 {
        /* color: ${props => props.theme.theme.colors.green}; */
        text-decoration: underline;
      }
    }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 12px;
  }
`

const Excerpt = styled.p`
  margin: 0;
`

const PostDetails = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-content: flex-start;
  padding: 0 12px 0 0;


  @media (max-width: 700px) {
    padding: 0 12px 12px 12px;

    h2 {
      font-size: 1.6rem;
    }
  }
`

const Author = styled.h3`
  margin: 6px 0 6px 0;
  font-weight: 500;
  font-size: 1rem;
`

export const PostsGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  grid-gap: 24px;
  padding: 24px 0 24px 24px;
  
  @media (max-width: 600px) {
    padding: 0 0 0 0; 
    margin: 24px 24px 0 24px;
  }
`

const ArticleInfo = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 0.85rem;
`

const PublishedBy = styled.span`
  /* font-style: italic; */
  margin: 0 4px 0 0;
  /* font-size: 0.8rem; */
`

const AuthorItem = styled.span`
  margin: 0 12px 0 0;
  font-weight: 600;
`

const ArticleDate = styled.span`
  /* color: ${props => props.theme.theme.colors.blue}; */
  color: ${props => props.theme.theme.text.quarternary};
  margin: 0 24px 0 0;
`

const HomeGrid = styled(ContainerMain)`
  box-sizing: border-box;
  padding: 0px;

  display: grid;
  grid-template-columns: 7fr 3fr;
  grid-gap: 24px;
  align-items: start;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }

  p {
    font-size: 0.9rem;
  }
`

export const SidebarGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  /* align-content: flex-start; */
  /* grid-template-columns: 1fr; */
  /* background-color: ${props => props.theme.theme.bg.primary}; */
  height: auto;
  grid-gap: 24px;
  border-radius: 8px;
  margin: 0 24px 0 0;

  h2, p {
    margin: 4px 0;
  }

  @media (max-width: 600px) {
    margin: 0 0px 0 0;
  }

`

export const SidebarPanel = styled.div`
  box-sizing: border-box;
  /* display: grid; */
  /* align-content: flex-start; */
  /* grid-template-columns: 1fr; */
  background-color: ${props => props.theme.theme.bg.primary};
  color: ${props => props.theme.theme.text.primary};
  /* border: 1px solid ${ props => props.theme.theme.border.secondary}; */
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.05);
  height: auto;
  /* grid-gap: 24px; */
  border-radius: 8px;
  padding: 16px;
  margin: 0px 0px 0px 0;

  p {
    font-size: 1rem;
  }

  h2, p {
    margin: 4px 0;
    font-family: 'Inter', sans-serif;
  }

  &.GoogleAdPanel {
    height: 300px;
  }

  &.about {
    margin: 24px 0px 0px 0;
  }

  @media (max-width: 600px) {
    margin: 0px 24px 0 24px;
  }
`

const SidebarLink = styled.h3`
  color: ${props => props.theme.theme.text.primary};
  border-bottom: 2px solid ${props => props.theme.theme.border.secondary};

  &:hover {
    /* background-color: ${props => props.theme.theme.border.tertiary}; */
    border-bottom: 2px solid ${props => props.theme.theme.colors.green};
  }
`

const tools = [
  {
    name: 'Coast FIRE Calculator',
    url: 'coast-fire-calc',
    icon: <CoastFire />,
    desc: 'How much do you need to save to Coast to FIRE?'
  },
  {
    name: 'FIRE Calculator',
    url: 'fire-calculator',
    icon: <Fire />,
    desc: 'Plan your journey to Financial Independence / Retire Early'
  },
  {
    name: 'Fat FIRE Calculator',
    url: 'fat-fire-calculator',
    icon: <FatFire />,
    desc: 'Plan your journey to FIRE with a fat nest egg'
  },
  {
    name: 'Barista FIRE Calculator',
    url: 'barista-fire-calc',
    icon: <BaristaFire />,
    desc: 'Plan your journey to FIRE with a fat nest egg'
  },
  {
    name: 'Rental Property Calculator for Real Estate Investing',
    url: 'rental-property-calculator',
    icon: <FcHome />,
    desc: 'Analyze key financial metrics for your next real estate deal'
  },
  {
    name: 'House Hacking Calculator',
    url: 'house-hacking-calculator',
    icon: <FcHome />,
    desc: 'Accelerate your path to financial freedom by being a live-in landlord'
  },
  {
    name: 'Savings Rate Calculator',
    url: 'savings-rate-calculator',
    icon: <FcMoneyTransfer />,
    desc: 'What is your Savings Rate?'
  },
  {
    name: 'Purchase Impact on FIRE Timeline Calculator',
    url: 'fire-purchase-impact-calculator',
    icon: <FcClock />,
    desc: 'Calculate how much a one-time purchase impacts your FIRE timeline'
  },
  {
    name: 'Windfall Impact on FIRE Timeline Calculator',
    url: 'fire-windfall-impact-calculator',
    icon: <FcClock />,
    desc: 'Calculate how much a windfall impacts your FIRE timeline'
  },
  {
    name: 'Compound Interest Calculator',
    url: 'compound-interest-calculator',
    icon: <FcPositiveDynamic />,
    desc: 'Visualize the incredible power of compound growth'
  },
  {
    name: 'Emergency Fund Calculator',
    url: 'emergency-fund-calculator',
    icon: <FcHighPriority />,
    desc: 'Visualize the incredible power of compound growth'
  },
  {
    name: 'Upwork Fee Calculator and Guide',
    url: 'upwork-fee-calculator',
    icon: <FcOnlineSupport />,
    desc: 'Calculate your take home earnings as a freelancer on Upwork'
  },
  {
    name: '15 Year vs 30 Year Morgage Calculator',
    url: '15-vs-30-year-mortgage-calculator',
    icon: <FcHome />,
    desc: 'Compare your monthly payments and total cost between a 15 year and a 30 year mortgage'
  },
  {
    name: 'Loan Payment Calculator',
    url: 'loan-calculator',
    icon: <FcCalendar />,
    desc: 'Calculate your loan payments over time and visualize your amortization schedule'
  },
  {
    name: 'Coast FIRE Jobs Database',
    url: 'coast-fire-jobs-database',
    icon: <FcList />,
    desc: 'A crowdsourced database of potential Coast FIRE jobs'
  },
  {
    name: 'USD Coin Interest Calculator',
    url: 'usdc-calculator',
    icon: <Usdc />,
    desc: 'Calculate how much interest you can earn on your USDC deposits'
  },
  {
    name: 'Electric Car Savings Calculator',
    url: 'electric-car-savings-calc',
    icon: <FcFlashOn />,
    desc: 'Discover how much you can save by switching to an electric vehicle'
  },
  {
    name: 'Gas Mileage Savings Calculator',
    url: 'gas-mileage-savings-calc',
    icon: <FcAutomotive />,
    desc: 'Compare annual fuel costs between two vehicles and calculate the break-even point'
  },
  {
    name: 'Hybrid vs Gas Savings Calculator',
    url: 'hybrid-vs-gas-savings-calculator',
    icon: <FcAutomotive />,
    desc: 'Compare annual fuel costs between a gas vehicle and a hybrid and calculate the break-even point'
  },
  {
    name: 'Portfolio Rebalancing Calculator',
    url: 'portfolio-rebalancing-calc',
    icon: <FcPieChart />,
    desc: 'Calculate how to rebalance your current portfolio to achieve your target asset allocations'
  },
  {
    name: 'Keeping up with Inflation Calculator',
    url: 'keeping-up-with-inflation-calculator',
    icon: <FcSportsMode />,
    desc: 'Calculate how much of a salary raise you need to keep up with inflation'
  },
  {
    name: 'Ethereum Staking Rewards Calculator',
    url: 'ethereum-staking-calculator',
    icon: <Eth />,
    desc: 'How much can you earn by staking your Ethereum (ETH)?'
  },
  {
    name: 'Kraken Polkadot Staking Calculator',
    url: 'kraken-polkadot',
    icon: <Dot />,
    desc: 'How much can you earn by staking your Polkadot (DOT) with Kraken?'
  },
  {
    name: 'Coinbase USD Coin (USDC) Interest Calculator',
    url: 'coinbase-usdc-calculator',
    icon: <Usdc />,
    desc: 'Calculate how much interest you can earn on your USDC deposits at Coinbase'
  },
  {
    name: 'Algorand (ALGO) Staking Rewards Calculator',
    url: 'algorand-staking-calculator',
    icon: <Algo />,
    desc: 'How much can you earn by staking your Algorand (ALGO)?'
  },
  {
    name: 'Cardano (ADA) Staking Rewards Calculator',
    url: 'cardano-staking-calculator',
    icon: <Ada />,
    desc: 'How much can you earn by staking your Cardano (ADA)?'
  },
  {
    name: 'Cosmos (ATOM) Staking Rewards Calculator',
    url: 'cosmos-staking-calculator',
    icon: <Atom />,
    desc: 'How much can you earn by staking your Cosmos (ATOM)?'
  },
  {
    name: 'Solana (SOL) Staking Rewards Calculator',
    url: 'solana-staking-calculator',
    icon: <Sol />,
    desc: 'How much can you earn by staking your Solana (SOL)?'
  },
  {
    name: 'Tezos (XTZ) Staking Rewards Calculator',
    url: 'tezos-staking-calculator',
    icon: <Xtz />,
    desc: 'How much can you earn by staking your Tezos (XTZ)?'
  },
  {
    name: 'Flow (FLOW) Staking Rewards Calculator',
    url: 'flow-staking-calculator',
    icon: <Flow />,
    desc: 'How much can you earn by staking your Flow (FLOW)?'
  },
  {
    name: 'Kusama (KSM) Staking Rewards Calculator',
    url: 'kusama-staking-calculator',
    icon: <Ksm />,
    desc: 'How much can you earn by staking your Kusama (KSM)?'
  },
  {
    name: 'Kava (KAVA) Staking Rewards Calculator',
    url: 'kava-staking-calculator',
    icon: <Kava />,
    desc: 'How much can you earn by staking your Kava (KAVA)?'
  },
  {
    name: 'Avalanche (AVAX) Staking Rewards Calculator',
    url: 'avalanche-staking-calculator',
    icon: <Avax />,
    desc: 'How much can you earn by staking your Avalanche (AVAX)?'
  }
]

const ToolItem = ( {toolItem} ) => {
  return(
    <Link to={`/tools/${toolItem.url}`}>
      <ToolItemContainer>
        {toolItem.icon}
          <h3>{toolItem.name}</h3>
      </ToolItemContainer>
    </Link>
  )
}

export const AdItem = ( {adItem} ) => {
  return(
    <a href={adItem.url} target="_blank">
      <AdItemContainer>
        <MdLocalOffer color={adItem.color} />
        <div>
          <h3><strong>{adItem.name}</strong>: {adItem.subtitle} <span className="subtitle">{adItem.desc}</span> </h3>
          {/* <h3><span className="subtitle">{adItem.subtitle}</span> </h3> */}
        </div>
      </AdItemContainer>
    </a>
  )
}

export const BookItem = ( {bookItem} ) => {
  return(
    <a href={bookItem.url} target="_blank">
      <AdItemContainer>
        <MdBookmark color={bookItem.color} />
        <h3><strong>{bookItem.name}</strong> by {bookItem.author}</h3>
      </AdItemContainer>
    </a>
  )
}

const ToolItemContainer = styled.div`
  padding: 8px;
  margin: 4px 0;
  box-sizing: border-box;
  display: grid;
  align-items: center;
  /* align-content: flex-start; */
  /* background-color: ${props => props.theme.theme.bg.tertiary}; */
  grid-template-columns: 25px 1fr;
  grid-template-rows: 36px;
  grid-gap: 8px;
  overflow: hidden;
  transition: background-color 0.2s ease-in-out;
  border-radius: 8px;

  svg {
    width: 100%;
    height: 100%;
  }

  h3{
    font-size: 1em;
    font-weight: 500;
    margin: 0;
    color: ${props => props.theme.theme.text.primary};
  }

  &:hover {
      /* border: 1px solid ${ props => props.theme.theme.border.tertiary}; */
    background-color: ${props => props.theme.theme.colors.transparentGreen};
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out;
    /* box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1); */

      h3 {
        /* color: ${props => props.theme.theme.colors.green}; */
        /* text-decoration: underline; */
      }
    }
`

const AdItemContainer = styled.div`
  padding: 8px;
  box-sizing: border-box;
  display: grid;
  align-items: flex-start;
  /* align-items: center; */
  /* align-content: flex-start; */
  /* background-color: ${props => props.theme.theme.bg.tertiary}; */
  grid-template-columns: 25px 1fr;
  /* grid-template-rows: 30px; */
  grid-gap: 8px;
  /* overflow: hidden; */
  transition: background-color 0.2s ease-in-out;
  border-radius: 8px;

  svg {
    width: 25px;
    height: 25px;
  }

  h3{
    font-size: 1em;
    font-weight: 400;
    margin: 0;
    color: ${props => props.theme.theme.text.primary};
  }

  h4{
    font-size: 1em;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
    color: ${props => props.theme.theme.text.primary};
  }

  &:hover {
      /* border: 1px solid ${ props => props.theme.theme.border.tertiary}; */
    background-color: ${props => props.theme.theme.colors.transparentGreen};
    border-radius: 8px;
    transition: background-color 0.2s ease-in-out;
    /* box-shadow: 0px 3px 5px 0px rgba(0,0,0,0.1); */

      h3 {
        /* color: ${props => props.theme.theme.colors.green}; */
        /* text-decoration: underline; */
      }
    }

  p {
    color: ${props => props.theme.theme.text.primary};
  }

  .subtitle {
    color: ${props => props.theme.theme.text.tertiary};
  }
`

const AdDisclaimer = styled.p`
  color: ${props => props.theme.theme.text.quarternary};
`

export const AdPanel = () => {
  const ads = [
    {
      name: 'Personal Capital app',
      subtitle: 'The ultimate free personal finance dashboard.',
      desc: 'Get a 360 degree, real-time view of your money and easily manage your entire financial life all in one place.',
      url: 'https://personalcapital.sjv.io/ORaRvz',
      color: "#04CFFF"
    },
    {
      name: 'Mint Mobile',
      subtitle: 'Premium wireless at the lowest price.',
      desc: `Get 10GB of 5G wireless data on T-Mobile's network for only $20/month.`,
      // url: 'https://mint-mobile.58dp.net/walletburst',
      url: 'http://fbuy.me/rYaXV',
      color: "#69AF85"
    },
    {
      name: 'Tiller',
      subtitle: 'Your financial life in a spreadsheet, automatically updated each day.',
      desc: 'Try free for 30 days.',
      url: 'https://www.awin1.com/cread.php?awinmid=18709&awinaffid=1001023',
      color: "#69C569"
    },
    {
      name: 'M1 Finance',
      subtitle: 'Invest, borrow, and spend all in one place.',
      desc: 'Get up to $4,000 when you open a new brokerage account.',
      url: 'https://m1finance.8bxp97.net/BXnJzL',
      color: '#00D4A3'
    },
    {
      name: 'Coinbase',
      subtitle: 'The easiest place to buy and sell cryptocurrency.',
      desc: 'Get $10 in free bitcoin when you sign up.',
      url: 'https://www.coinbase.com/join/549f3ef5e81d8e7f99000004',
      color: "#1552EF"
    }
    // {
    //   name: 'Robinhood',
    //   subtitle: 'Comission-free investing.',
    //   desc: 'Sign up and get your first stock for free.',
    //   url: 'https://join.robinhood.com/andrewz119',
    //   color: "#00C807"
    // },
    // {
    //   name: 'Chase Freedom Unlimited and Flex',
    //   subtitle: '',
    //   desc: 'Earn a $200 bonus and get up to 5% cash back with no annual fee.',
    //   url: 'https://www.referyourchasecard.com/18/U6F4EOBC0G',
    //   color: "#117ACA"
    // }
    // {
    //   name: '',
    //   desc: '',
    //   url: '',
    //   color: ""
    // }
  ]

  const books = [
    {
      name: 'The Psychology of Money: Timeless lessons on wealth, greed, and happiness',
      author: 'Morgan Housel',
      url: 'https://amzn.to/3sbPaK1',
      color: "#7AA48D"
    },
    {
      name: 'The Defining Decade: Why Your Twenties Matter - And How to Make the Most of Them Now',
      author: 'Meg Jay',
      url: 'https://amzn.to/3cZ0EL1',
      color: "#3180C3"
    },
    {
      name: `Man's Search for Meaning`,
      author: 'Viktor E Frankl',
      url: 'https://amzn.to/31XHoHe',
      color: "#8E8AA9"
    },
    {
      name: 'The Millionaire Next Door',
      author: 'William D. Danko',
      url: 'https://amzn.to/3ms3GKR',
      color: "#69AF85"
    },
    {
      name: 'The Sovereign Individual: Mastering the Transition to the Information Age',
      author: 'James Dale Davidson and Lord William Rees-Mogg',
      url: 'https://amzn.to/3d3MTLb',
      color: "#F37F20"
    },
    {
      name: 'Sapiens: A Brief History of Humankind',
      author: 'Yuval Noah Harari',
      url: 'https://amzn.to/2PBC61R',
      color: "#F41719"
    }
    // {
    //   name: '',
    //   desc: '',
    //   url: '',
    //   color: ""
    // }
  ]

  return (
    <SidebarPanel>
      <h2>My Favorite Products 📌</h2>
      {ads.map(ad => (<AdItem adItem={ad} />)) }
      {/* <h2>My Favorite Books 📖</h2>
      {books.map(item => (<BookItem bookItem={item} />)) } */}
      <AdDisclaimer>
        Note: By utilizing these links, you may help me to earn compensation
        which in turn helps me to run and improve WalletBurst.
      </AdDisclaimer>
    </SidebarPanel>
  )
}

const ThemeButton = styled(Button)`
  &.MuiButton-root{
    background-color: ${props => props.theme.theme.colors.blue};
    color: ${props => props.theme.theme.colors.white};
    border: 0px solid ${props => props.theme.theme.colors.lightBrown};
    text-transform: none;
    font-size: 1rem;
    margin: 12px 12px 0 0;
    border-radius: 20px;
    padding: 6px 10px 6px 18px;

    &:hover {
      background-color: ${props => props.theme.theme.colors.blue};
      opacity: 0.8;
    }
  }
  &.MuiButton-outlined {
    border: 0px solid ${props => props.theme.theme.colors.green};
  }
`

const IndexPage = () => {

  return (
    <Layout>
      <SEO
        title={'Home'}
        description={"Home page"}
      />

      <Hero />

      <FeaturedInSection />

      <GumroadAd />

      <HomeGrid>
        <PostsGrid>
        </PostsGrid>
        <SidebarGrid>
          <SidebarPanel className="about">
            <h2>About Me 🙋‍♂️</h2>
            <p>Hey there, welcome to WalletBurst!</p>
            <p>
              I'm Andrew, a 27 year old guy living in the SF bay area. I write about gaining lifestyle freedom through smart personal finance.
              Here I share the tools and insights that I am using on my journey to reach financial independence by age 30.
            </p>
            <p>
              You can read <Link to="/my-story/">My Story</Link> here or learn more on the <Link to="/about/">About</Link> page.
            </p>
          </SidebarPanel>

          <GoogleAdWrapper />

          <SidebarPanel>
            <EmailOptin />
          </SidebarPanel>

          < MintMobileAd />

          <SidebarPanel>
            <h2>Tools 🧰</h2>
            {tools.map(tool => (<ToolItem toolItem={tool} />)) }

            {/* <Link to='/tools/coast-fire-calc'>
              <ThemeButton variant="outlined" >
                Coast FIRE Calculator
                <MdChevronRight size="1.6rem" />
              </ThemeButton>
            </Link>

            <Link to='/tools/compound-interest-calculator'>
              <ThemeButton variant="outlined" >
                Compound Interest Calculator
                <MdChevronRight size="1.6rem" />
              </ThemeButton>
            </Link>

            <Link to='/tools/savings-rate-calculator'>
              <ThemeButton variant="outlined" >
                Savings Rate Calculator
                <MdChevronRight size="1.6rem" />
              </ThemeButton>
            </Link>

            <Link to='/tools/fire-calculator'>
              <ThemeButton variant="outlined" >
                FIRE Calculator
                <MdChevronRight size="1.6rem" />
              </ThemeButton>
            </Link> */}

          </SidebarPanel>

          <GoogleAdWrapper />

          <AdPanel />



          {/* <SidebarPanel>
            <h2>My Favorite Products</h2>
            {ads.map(ad => (<AdItem adItem={ad} />)) }
            <AdDisclaimer>
              Note: I only recommend products that I myself use. By utilizing these links, you may help me to earn compensation
              which in turn helps me to run and improve WalletBurst.
            </AdDisclaimer>

          </SidebarPanel> */}

        </SidebarGrid>
      </HomeGrid>

      {/* <Container>
        <h1 hidden>Welcome to {site.title}</h1>
        {postNodes && (
          <BlogPostPreviewList
            title='Latest blog posts'
            nodes={postNodes}
            browseMoreHref='/archive/'
          />
        )}
      </Container> */}
    </Layout>
  )
}

export default IndexPage
